html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

html {
  color: #fff;
  min-height: 100%;
  background: linear-gradient(220.55deg, #4063bc 0%, #6b0013 100%);
  font-size: 16px;
  font-weight: bold;
}

a, a:visited, a:active, a:hover {
  color: inherit;
}

body {
  text-align: justify;
  min-width: 320px;
  font-family: Manrope, sans-serif;
}

main {
  width: 256px;
  min-height: 100vh;
  margin: 0 auto;
}

canvas {
  z-index: -1;
  -webkit-user-select: none;
  position: absolute;
  top: 0;
  left: 0;
}

canvas.active {
  z-index: 2;
}

h1 {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: normal;
}

h2 {
  text-align: center;
  margin-top: 0;
  font-size: 1em;
  font-weight: normal;
}

h1 {
  justify-content: space-between;
  display: flex;
}

.blast {
  display: inline-block;
}

a .blast {
  text-decoration: underline;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media only screen and (min-width: 630px) {
  html {
    font-size: 24px;
  }

  main {
    width: 500px;
  }

  h1 {
    font-size: 2.7rem;
  }
}

/*# sourceMappingURL=index.5e1973cd.css.map */
